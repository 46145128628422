import {
  QueryClient,
  QueryCache,
  MutationCache,
  UseMutationOptions,
  DefaultError
} from '@tanstack/react-query'
import { defaultLogger } from '../logger'
import { HttpStatusCode, isAxiosError } from 'axios'
import { ApiError } from '@services/api'

const RETRY_COUNT = 3
const JWT_REFRESH_URL = 'v2/auth/jwt/refresh'

export function createQueryClient(): QueryClient {
  const queryCache = new QueryCache({
    onError(error) {
      defaultLogger.error('Network error', { error })
    }
  })

  const mutationCache = new MutationCache({
    onError(error) {
      defaultLogger.error('Network error', { error })
    }
  })

  return new QueryClient({
    queryCache,
    mutationCache,
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: getShouldRetry
      }
    }
  })
}

function getShouldRetry(count: number, error: unknown) {
  if (count >= RETRY_COUNT) return false

  const axiosError =
    error instanceof ApiError && isAxiosError(error.source)
      ? error.source
      : null

  if (axiosError) {
    const status = axiosError.response?.status
    const url = axiosError.config?.url

    switch (status) {
      case HttpStatusCode.Unauthorized:
        // retry everything except token refresh
        return url != JWT_REFRESH_URL

      case HttpStatusCode.Forbidden:
        return false

      default:
        return true
    }
  }

  return false
}

export const queryClient = createQueryClient()

/**
 * Helper type to make it easier to pass callbacks to mutation hooks by
 * 1. Reordering generic arguments via putting TVariable and TData types first as they're used more often
 *    this is also more natural to have args type first and then return value similary how we define function type.
 * 2. Allowing only necessary overrides, in this case callbacks (this can and should be extended as needed).
 */
export type MutationOptions<
  TVariables = void,
  TData = unknown,
  TContext = unknown,
  TError = DefaultError
> = Pick<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'onSuccess' | 'onError' | 'onSettled' | 'onMutate'
>
